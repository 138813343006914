import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        { 
            path: '/', 
            element: (
                <Navigate to="/tracnghiemtinhcach"/>
            )
        },
        { 
            path: '/*', 
            element: (
                <Navigate to="/tracnghiemtinhcach"/>
            )
        },
        MainRoutes
    ]);
}
