export const AUTH_API = {
    SignIn: 'login',
    CreateUser: 'createUser',
    DeleteUser: 'activeUser',
};

export const PROJECT = {
    NoneOfNft: 'project/none-nft',
    Detail: 'project/detail/'
};

export const NFT = {
    Issue: 'nft/issueNft',
    Detail: 'nft/detail/',
    Extend: 'nft/extend/',
    UpdateRating: 'nft/'
}
