// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconBasket, IconLayoutKanban, IconNfc, IconListCheck } from '@tabler/icons';
import { OverrideIcon } from 'types';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconBasket,
    IconLayoutKanban,
    IconNfc,
    IconListCheck
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

interface DashboardMenuProps {
    id: string;
    title: React.ReactNode | string;
    type: string;
    children: {
        id: string;
        title: React.ReactNode | string;
        type: string;
        url: string;
        icon: OverrideIcon;
        breadcrumbs: boolean;
    }[];
}

const dashboardAdmin: DashboardMenuProps = {
    id: 'dashboard-admin',
    title: '',
    type: 'group',
    children: [
        {
            id: 'tracnghiemtinhcach',
            title: <FormattedMessage id="Trắc nghiệm tính cách" />,
            type: 'item',
            url: '/tracnghiemtinhcach',
            icon: icons.IconListCheck,
            breadcrumbs: false
        },
    ]
};

export default dashboardAdmin;
