import { useEffect, useRef, useState } from 'react';

// material-ui
import {
    Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// project imports

// assets

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    // const { logout } = useAuth();
    const navigate = useNavigate();

    const [open] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Grid item>
                {/* <Button onClick={handleToKetqua} sx={{borderColor: '#680102', color: '#680102' }} variant="outlined" startIcon={<IconLogout />}>
                    Xem nhiều kết quả
                </Button> */}
            </Grid>
        </>
    );
};

export default ProfileSection;
